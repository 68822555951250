const Logo = () => {
  const logo = new URL('./logo.png', import.meta.url).toString();

  return (
    <div className="pt-6 pb-3">
      <img height={32} width={114} alt="FavritLogo" src={logo} />
    </div>
  );
};

export default Logo;
