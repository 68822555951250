import { useMutation } from '@tanstack/react-query';
import config from '../../../config';
import useDeviceId from './useDeviceId';

type PinLoginRequest = { pin: string; deviceId: string; locationId: number };

const usePin = () => {
  const deviceId = useDeviceId();

  return useMutation({
    mutationKey: ['pin-login'],
    mutationFn: (dto: { pin: string; locationId: number }) =>
      pinLogin({ ...dto, deviceId }),
    onSuccess: (data: LoginResponse) => {
      localStorage.setItem('REFRESH_TOKEN', data.refreshToken);
    },
  });
};

const pinLogin = async (credentials: PinLoginRequest) => {
  const loginResponse = await pinLoginFetch(
    `${config.baseUrl}/v1/pin`,
    credentials
  );

  if (!loginResponse.ok) {
    throw new Error('Failed to login');
  }

  const json = await loginResponse.json();

  if (!(json as LoginResponse).refreshToken) {
    throw new Error('Failed to login');
  }

  const jwtResponse: LoginResponse = json as LoginResponse;

  return jwtResponse;
};

const pinLoginFetch = async (url: string, pinLoginRequest: PinLoginRequest) => {
  const postData = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(pinLoginRequest),
  };
  return fetch(url, postData);
};

type LoginResponse = {
  token: string;
  refreshToken: string;
};

export default usePin;
