export const getRandomString = (length: number) => {
  const randomChars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const uInt8ToAscii = (number: number) =>
    randomChars.charAt(Math.floor((number / 255) * randomChars.length));
  const randomArray = Array.from(
    window.crypto.getRandomValues(new Uint8Array(length))
  );
  return randomArray.map(uInt8ToAscii).join('');
};

const useDeviceId = () => {
  const deviceId = localStorage.getItem('device_id');

  if (!deviceId) {
    const newDeviceId = getRandomString(16);
    localStorage.setItem('device_id', newDeviceId);
    return newDeviceId;
  }

  return deviceId;
};

export default useDeviceId;
