import { useEffect } from 'react';

export const useKeyboard = (handleInput: (change: string) => void) => {
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.code.includes('Digit')) {
        handleInput(getNumberFromCode(e.code));
      }

      if (e.code === 'Backspace') {
        handleInput('backspace');
      }
    };

    addEventListener('keydown', onKeyDown);

    return () => {
      removeEventListener('keydown', onKeyDown);
    };
  }, [handleInput]);
};

const getNumberFromCode = (code: string) => {
  const number = code.replace('Digit', '');
  return number;
};
