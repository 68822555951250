import React, { useCallback, useState } from 'react';
import { Button, TextInput } from '@ordr-as/umami-react';
import config from '../../../config';
import Logo from '../../../assets/Logo';

interface LoginProps {
  login: (credentials: { username: string; password: string }) => void;
  loginFailed: boolean | undefined;
}

const LoginForm: React.FC<LoginProps> = (props) => {
  const { login } = props;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const submitCredentials = useCallback(
    () => login({ username, password }),
    [username, password, login]
  );

  const setUsernameAsLowerCase = useCallback((value: string) => {
    setUsername(value.toLocaleLowerCase());
  }, []);

  return (
    <main className="flex flex-col col-span-full gap-3 w-min mx-auto items-center">
      <Logo />
      <span className="flex flex-col col-span-full gap-5 w-min mx-auto">
        <TextInput
          label="Email"
          type="email"
          onChange={setUsernameAsLowerCase}
          value={username}
        ></TextInput>
        <TextInput
          label="Password"
          type="password"
          onChange={setPassword}
        ></TextInput>
        <Button kind="primary" onClick={submitCredentials}>
          Log In
        </Button>
        <a
          className="text-xs text-center text-grey-dark"
          href={`${config.adminUrl}/#/auth/request-password`}
        >
          Forgot your password?
        </a>
        {props.loginFailed && (
          <div className="bg-yellow-light rounded-lg">
            <p>You have entered an invalid username or password</p>
          </div>
        )}
      </span>
    </main>
  );
};

export default LoginForm;
