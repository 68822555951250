import config from './config';

export interface Cookie {
  name: string;
  value: string;
  days: number;
  path: string;
  domain: string;
  secure: boolean;
  sameSite: string;
}

export const setAuthenticationUpdatedCookie = () => {
  const cookieParams: Cookie = {
    name: 'authentication_updated',
    value: Date.now().toString(),
    days: 1,
    path: '/',
    domain: getDomain(),
    secure: false,
    sameSite: 'Lax',
  };

  setCookie(cookieParams);
};

const getDomain = () => {
  if (config.env === 'prod') {
    return '.favrit.com';
  }

  if (config.env === 'qa') {
    return '.qa.favrit.com';
  }

  return 'localhost';
};

const setCookie = (cookieParams: Cookie) => {
  const { name, value, days, path, domain, secure, sameSite } = cookieParams;

  let cookieString = `${name}=${value}`;

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    cookieString += `; expires=${date.toUTCString()}`;
  }

  if (path) {
    cookieString += `; path=${path}`;
  }

  if (domain) {
    cookieString += `; domain=${domain}`;
  }

  if (secure) {
    cookieString += `; secure`;
  }

  if (sameSite) {
    cookieString += `; samesite=${sameSite}`;
  }

  document.cookie = cookieString;
};
