import { useQuery } from '@tanstack/react-query';
import config from '../../../config';

const useLocationName = (locationId: number) => {
  const query = async () => {
    const response = await fetch(
      `${config.ordrUrl}/v1/locations/${locationId}/name`
    );
    const data = await response.json();

    return data.locationName;
  };

  return useQuery({
    queryKey: ['location', locationId],
    queryFn: query,
  });
};

export default useLocationName;
