import { useState } from 'react';
import config from '../../../config';
import jwt_decode from 'jwt-decode';
import { setAuthenticationUpdatedCookie } from '../../../cookie';

type Credentials = { username: string; password: string };

type DecodedJwt = { exp: number };

export interface StoredToken {
  refreshToken: string;
  username: string;
}
export interface TokenStorage {
  [key: string]: StoredToken;
}

const useAuth = () => {
  const localStorageToken = localStorage.getItem('REFRESH_TOKEN');
  const tokenHasExpired = hasTokenExpired(localStorageToken);

  const initialState = !tokenHasExpired ? localStorageToken : null;
  const [token, setToken] = useState<string | null | undefined>(initialState);
  const [loginFailed, setLoginFailed] = useState<boolean | undefined>(
    undefined
  );

  const handleLogin = async (credentials: Credentials) => {
    const loginResponse = await login(credentials);
    if (loginResponse) {
      const token = loginResponse?.refreshToken;
      setToken(token);
      localStorage.setItem('REFRESH_TOKEN', token || '');

      saveWithPin(loginResponse, credentials.username);

      setLoginFailed(false);
    } else {
      setLoginFailed(true);
    }
  };
  return { token, login: handleLogin, loginFailed };
};

const hasTokenExpired = (token: string | null) => {
  if (!token?.length) {
    return true;
  }

  try {
    const decodedToken = jwt_decode<DecodedJwt>(token);
    const { exp: expirationTime } = decodedToken;
    const now = Date.now() / 1000;
    const tokenHasExpired = expirationTime < now;
    return tokenHasExpired;
  } catch (e) {
    console.error(e);
    return true;
  }
};

const saveWithPin = (loginResponse: LoginResponse, username: string) => {
  const old = JSON.parse(
    localStorage.getItem('tokens') || '{}'
  ) as TokenStorage;
  const newToken = {
    refreshToken: loginResponse.refreshToken,
    username,
  };

  const newTokens = {
    ...old,
    [loginResponse.pin || '0000']: newToken,
  };

  setAuthenticationUpdatedCookie();
  localStorage.setItem('tokens', JSON.stringify(newTokens));
};

const login = async (credentials: { username: string; password: string }) => {
  const loginResponse = await loginFetch(
    `${config.baseUrl}/v2/login`,
    credentials
  );

  if (!loginResponse.ok) {
    return false;
  }

  const json = await loginResponse.json();

  if (!(json as LoginResponse).refreshToken) {
    return false;
  }

  const jwtResponse: LoginResponse = json as LoginResponse;

  return jwtResponse;
};

const loginFetch = async (
  url: string,
  credentials: { username: string; password: string }
) => {
  const postData = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  };
  return fetch(url, postData);
};

type LoginResponse = {
  token: string;
  refreshToken: string;
  pin?: string;
};

export default useAuth;
