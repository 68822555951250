import { useEffect } from 'react';
import useLogout from '../hooks/useLogout';

const Logout = () => {
  const logout = useLogout();
  const urlParams = new URLSearchParams(window.location.search);
  const userInputRedirectUrl = urlParams.get('redirect_url');
  const nonce = urlParams.get('nonce');
  const client_id = urlParams.get('client_id');
  const location_id = urlParams.get('location_id');

  const redirect = () => {
    if (location_id) {
      urlParams.set('location_id', location_id.toString());
      window.location.replace(`/v3/pin?${urlParams.toString()}`);
      return;
    }

    window.location.replace(`/v3/login?${urlParams.toString()}`);
  };

  useEffect(() => {
    if (!logout.isPending) {
      logout.mutate(
        { token: localStorage.getItem('REFRESH_TOKEN')!! },
        {
          onSettled: () => {
            localStorage.removeItem('REFRESH_TOKEN');

            if (userInputRedirectUrl && nonce && client_id) {
              return redirect();
            }

            history.back();
          },
        }
      );
    }
  }, []);

  return (
    <main className="max-w-2xl mt-20 mx-auto">
      <p>
        You have been logged out. Please close all open tabs authenticated with
        Favrit
      </p>
    </main>
  );
};

export default Logout;
