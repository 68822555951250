import { ChevronLeftIcon } from '@ordr-as/icons';
import { Button } from '@ordr-as/umami-react';
import { FC } from 'react';

interface Props {
  navigateTo: VoidFunction;
  className?: string;
  title?: string;
}

const BackButton: FC<Props> = ({ navigateTo, className, title }) => {
  return (
    <Button
      onClick={navigateTo}
      className={`p-4 w-full bg-purple-lightest flex ${className}`}
    >
      <div className="flex items-center flex-1">
        <ChevronLeftIcon className="stroke-grey-dark stroke-0" />
        <span className="pl-1 text-grey-dark text-small">Back</span>
      </div>
      {title && <h4 className="">{title}</h4>}
      <div className="flex-1" />
    </Button>
  );
};

export default BackButton;
