import { useEffect } from 'react';

const Logout = () => {
  useEffect(() => {
    localStorage.removeItem('auth:jwt:token');
    localStorage.removeItem('REFRESH_TOKEN');
  }, []);

  return (
    <main className="max-w-2xl mt-20 mx-auto">
      <p>
        You have been logged out. Please close all open tabs authenticated with
        Favrit
      </p>
    </main>
  );
};

export default Logout;
