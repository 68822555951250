import { useEffect, useState } from 'react';
import config from '../config';

const useRedirectUrl = (
  userInputRedirectUrl: string | null,
  clientId: string | null
) => {
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>();
  const [redirectUrlError, setRedirectUrlError] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    if (!redirectUrl && userInputRedirectUrl && clientId) {
      fetch(`${config.baseUrl}/client/${clientId}/redirect-url`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ redirectUrl: userInputRedirectUrl }),
      }).then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            setRedirectUrl(data.redirectUrl);
            setRedirectUrlError(false);
          });
        } else {
          setRedirectUrlError(true);
        }
      });
    }
  }, [
    redirectUrl,
    setRedirectUrl,
    userInputRedirectUrl,
    clientId,
    setRedirectUrlError,
  ]);

  return { redirectUrl, redirectUrlError };
};

export default useRedirectUrl;
