import { useEffect, useState } from 'react';

const useIOS = () => {
  const [iosAppVersion, setIosAppVersion] = useState(
    localStorage.getItem('ios_app_version')
  );
  const [iosReady, setIosReady] = useState(sessionStorage.getItem('ios_ready'));

  useEffect(() => {
    const setIosAppVersionAndReady = async () => {
      setIosAppVersion(localStorage.getItem('ios_app_version'));
      setIosReady(sessionStorage.getItem('ios_ready'));
    };

    window.addEventListener('storage', setIosAppVersionAndReady);

    return () =>
      window.removeEventListener('storage', setIosAppVersionAndReady);
  }, [setIosAppVersion, setIosReady]);

  return { iosAppVersion, iosReady };
};

export default useIOS;
