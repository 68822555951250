import { useState } from 'react';
import useRedirectUrl from '../../../hooks/useRedirectUrl';
import LoginForm from '../../v2/Login/LoginForm';
import useAuthV3 from '../hooks/useAuthV3';
import BackButton from './BackButton';
import { setAuthenticationUpdatedCookie } from '../../../cookie';
import useIOS from '../hooks/useIOS';
import { checkIfUserHasAccessToLocation } from '../../../accessControl';
import useLogout from '../hooks/useLogout';

const LoginV3 = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const userInputRedirectUrl = urlParams.get('redirect_url');
  const clientId = urlParams.get('client_id');
  const nonce = urlParams.get('nonce');
  const locationId = urlParams.get('location_id');
  const [loginFailed, setLoginFailed] = useState<boolean>(false);

  const { redirectUrl, redirectUrlError } = useRedirectUrl(
    userInputRedirectUrl,
    clientId
  );

  const onLoginSuccess = (token: string) => {
    const urlParams = new URLSearchParams();
    urlParams.set('refresh-token', token);
    urlParams.set('nonce', nonce || '');
    setAuthenticationUpdatedCookie();
    window.location.replace(`${redirectUrl}?${urlParams.toString()}`);
    setLoginFailed(false);
  };

  const redirectToPin = () => {
    window.location.replace(`/v3/pin?${urlParams.toString()}`);
  };

  const onLoginFailed = () => {
    setLoginFailed(true);
  };

  const { login } = useAuthV3({
    onSuccess: onLoginSuccess,
    onFailure: onLoginFailed,
  });

  if (!userInputRedirectUrl || !clientId) {
    return <p>No redirect_url or clientID given</p>;
  }
  if (redirectUrlError) {
    return <p>Invalid redirectUrl please contact us at support@favrit.com</p>;
  }

  return (
    <>
      {locationId && <BackButton navigateTo={redirectToPin} />}
      <LoginForm login={login} loginFailed={loginFailed} />
    </>
  );
};

export default LoginV3;
