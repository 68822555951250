import { useEffect } from 'react';
import useAuthV2 from '../hooks/useAuth';
import useRedirectUrl from '../../../hooks/useRedirectUrl';
import LoginForm from './LoginForm';

const LoginV2 = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const userInputRedirectUrl = urlParams.get('redirect_url');
  const clientId = urlParams.get('client_id');
  const nonce = urlParams.get('nonce');

  const { redirectUrl, redirectUrlError } = useRedirectUrl(
    userInputRedirectUrl,
    clientId
  );
  const { token, login, loginFailed } = useAuthV2();

  useEffect(() => {
    if (redirectUrl && token) {
      const urlParams = new URLSearchParams();
      urlParams.set('refresh-token', token);
      urlParams.set('nonce', nonce || '');
      window.location.replace(`${redirectUrl}?${urlParams.toString()}`);
    }
  }, [token, redirectUrl, nonce]);

  if (!userInputRedirectUrl || !clientId) {
    return <p>No redirect_url or clientID given</p>;
  }
  if (redirectUrlError) {
    return <p>Invalid redirectUrl please contact us at support@favrit.com</p>;
  }
  return <LoginForm login={login} loginFailed={loginFailed} />;
};

export default LoginV2;
