interface Config {
  qa: AppConfig;
  prod: AppConfig;
  local: AppConfig;
}
export type ENV = keyof Config;

interface AppConfig {
  env: ENV;
  baseUrl: string;
  adminUrl: string;
  ordrUrl: string;
}

const getConfig: () => AppConfig = () => {
  // defaults to local profile
  const env = (import.meta.env.VITE_ENV as ENV) || 'local';

  const c: Config = {
    local: {
      env,
      baseUrl: `http://localhost:8081`,
      adminUrl: `https://admin.qa.favrit.com`,
      ordrUrl: `https://qa.favrit.com/ws/ordr-service/public`,
    },
    qa: {
      env,
      baseUrl: `https://qa.favrit.com/ws/authentication-service`,
      adminUrl: `https://admin.qa.favrit.com`,
      ordrUrl: `https://qa.favrit.com/ws/ordr-service/public`,
    },
    prod: {
      env,
      baseUrl: `https://favrit.com/ws/authentication-service`,
      adminUrl: `https://admin.favrit.com`,
      ordrUrl: `https://favrit.com/ws/ordr-service/public`,
    },
  };
  return c[env];
};

const config = getConfig();
export default config;
