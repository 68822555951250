import { Button } from '@ordr-as/umami-react';
import { FC, useState } from 'react';
import Logo from '../../../assets/Logo';
import { useKeyboard } from './useKeyboard';
import usePin from '../hooks/usePin';
import useIOS from '../hooks/useIOS';
import useLocationName from '../hooks/useLocationName';

interface Props {
  onSuccess: (refreshToken: string) => void;
  locationId: number;
}

const PinForm: FC<Props> = ({ onSuccess, locationId }) => {
  const [pin, setPin] = useState<string>('');
  const { mutate, isPending, isError, reset } = usePin();
  const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
  const { iosAppVersion } = useIOS();
  const { data: locationName } = useLocationName(locationId);

  const getCircleClassNames = (index: number) => {
    return `w-4 h-4 border rounded-full ${
      pin.length > index ? 'bg-brand-black' : ''
    }`;
  };

  const handleNumberClick = (number: string) => {
    if (number === 'backspace') {
      return setPin(pin.slice(0, pin.length - 1));
    }

    const newPin = pin + number;

    if (newPin.length === 4) {
      mutate(
        { pin: newPin, locationId },
        {
          onSuccess: (data) => {
            onSuccess(data.refreshToken);
          },
          onSettled: () => setPin(''),
        }
      );
      return;
    }

    reset();
    setPin(newPin);
  };

  useKeyboard(handleNumberClick);

  const redirectToLogin = () => {
    const urlParams = new URLSearchParams(window.location.search);

    if (iosAppVersion) {
      urlParams.set('SHOW_IOS_LOGIN', 'true');
    }

    urlParams.set('location_id', locationId.toString());
    window.location.replace(`/v3/login?${urlParams.toString()}`);
  };

  return (
    <div className="flex flex-col items-center">
      <div className="flex max-w-[350px] items-center ml-auto mr-auto flex-col px-4">
        <Logo />
        {locationName && <p className="text-grey-dark p-3 ">{locationName}</p>}
        <div className="flex flex-col items-center gap-4 pt-2">
          {isError ? (
            <div className="flex flex-col items-center h-[60px]">
              <p className="text-sm">Invalid PIN</p>
              <p className="text-center text-sm">
                Are you sure you are logged in with email and password?
              </p>
            </div>
          ) : (
            <div className="flex flex-col items-center gap-3 h-[60px] pb-5">
              <p className="font-bold">Enter PIN</p>
              <div className="flex gap-4">
                <div className={getCircleClassNames(0)} />
                <div className={getCircleClassNames(1)} />
                <div className={getCircleClassNames(2)} />
                <div className={getCircleClassNames(3)} />
              </div>
            </div>
          )}
        </div>
        <div className="grid grid-wr grid-cols-3 gap-x-5 gap-y-4 tablet:gap-x-6 tablet:gap-y-5 justify-items-center">
          {numbers.map((number, index) => (
            <Button
              key={index}
              isDisabled={isPending}
              kind="secondary"
              onClick={() => handleNumberClick(number)}
              className={`text-center text-2xl font-semibold rounded-full border-grey-medium active:bg-purple-lightest w-[72px] h-[72px] focus:border-grey-medium ${
                number === '0' && 'col-start-2'
              }`}
            >
              {number}
            </Button>
          ))}
          {pin.length > 0 && (
            <Button
              className="w-[72px] h-[72px] flex active:bg-purple-lightest focus:outline-none "
              isDisabled={isPending}
              onClick={() => setPin(pin.slice(0, pin.length - 1))}
            >
              Delete
            </Button>
          )}
        </div>
      </div>
      <div className="flex flex-col max-w-[400px] ml-auto mr-auto px-4 items-center pt-5 justify-center gap-2">
        <Button
          kind="secondary"
          onClick={redirectToLogin}
          className="rounded-full text-sm w-[150px] h-[36px] text-center"
        >
          Log in with email
        </Button>
        <p className="text-center text-small">
          To be able to quickly log in with PIN, you have to first log in to
          this device with your email.
        </p>
        <p className="text-small text-center text-grey-dark">
          Contact your manager if you have forgotten your PIN
        </p>
      </div>
    </div>
  );
};

export default PinForm;
