import jwt_decode from 'jwt-decode';

interface DecodedToken {
  sub: string;
  exp: number;
  permissions?: PermissionDto[];
  isAdmin?: boolean;
}

interface PermissionDto {
  locationIds: string[];
  permissions: string[];
}

export const checkIfUserHasAccessToLocation = (
  token: string,
  locationId: number
) => {
  const decodedToken = decodeToken(token);
  if (decodedToken.isAdmin) {
    return true;
  }

  return (
    decodedToken.permissions?.some((p) =>
      p.locationIds.includes(locationId.toString())
    ) || false
  );
};

const decodeToken = (token: string) => {
  return jwt_decode<DecodedToken>(token);
};
