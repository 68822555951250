import { setAuthenticationUpdatedCookie } from '../../../cookie';
import useRedirectUrl from '../../../hooks/useRedirectUrl';
import usePin from '../hooks/usePin';
import PinForm from './PinForm';

const Pin = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const userInputRedirectUrl = urlParams.get('redirect_url');
  const clientId = urlParams.get('client_id');
  const nonce = urlParams.get('nonce');
  const locationIdString =
    urlParams.get('location_id') || localStorage.getItem('location_id');
  const locationId = locationIdString ? parseInt(locationIdString) : undefined;

  const { redirectUrlError, redirectUrl } = useRedirectUrl(
    userInputRedirectUrl,
    clientId
  );

  const redirectBack = (token: string) => {
    localStorage.setItem('location_id', locationId?.toString()!!);
    const urlParams = new URLSearchParams();
    urlParams.set('refresh-token', token);
    urlParams.set('nonce', nonce || '');
    setAuthenticationUpdatedCookie();
    window.location.replace(`${redirectUrl}?${urlParams.toString()}`);
  };

  if (!userInputRedirectUrl || !clientId || !nonce || !locationId) {
    return <p>No redirect_url or clientID or locationId given</p>;
  }
  if (redirectUrlError) {
    return <p>Invalid redirectUrl please contact us at support@favrit.com</p>;
  }

  return <PinForm locationId={locationId} onSuccess={redirectBack} />;
};

export default Pin;
