import jwt_decode from 'jwt-decode';
import config from '../../../config';
import useDeviceId from './useDeviceId';
import useLogout from './useLogout';
import useIOS from './useIOS';
import { checkIfUserHasAccessToLocation } from '../../../accessControl';

type Credentials = { username: string; password: string };

type DecodedJwt = { exp: number };

export interface StoredToken {
  refreshToken: string;
  username: string;
}
export interface TokenStorage {
  [key: string]: StoredToken;
}

const useAuthV3 = (props: {
  onSuccess: (refreshToken: string) => void;
  onFailure: () => void;
}) => {
  const deviceId = useDeviceId();
  const { iosAppVersion } = useIOS();
  const locationId = new URLSearchParams(window.location.search).get(
    'location_id'
  );

  const handleLogin = async (credentials: Credentials) => {
    const loginResponse = await login({ ...credentials, deviceId });
    if (loginResponse) {
      if (iosAppVersion && locationId) {
        const userHasAccessToLocation = checkIfUserHasAccessToLocation(
          loginResponse.accessToken,
          Number(locationId)
        );

        if (!userHasAccessToLocation) {
          return props.onFailure();
        }
      }

      localStorage.setItem('REFRESH_TOKEN', loginResponse.refreshToken);
      return props.onSuccess(loginResponse.refreshToken);
    }
    return props.onFailure();
  };

  const checkTokenInLocalStorage = () => {
    const token = localStorage.getItem('REFRESH_TOKEN');

    if (token && !hasTokenExpired(token)) {
      return props.onSuccess(token);
    }

    return props.onFailure();
  };

  return { login: handleLogin, checkTokenInLocalStorage };
};

const hasTokenExpired = (token: string | null) => {
  if (!token?.length) {
    return true;
  }

  try {
    const decodedToken = jwt_decode<DecodedJwt>(token);
    const { exp: expirationTime } = decodedToken;
    const now = Date.now() / 1000;
    const tokenHasExpired = expirationTime < now;
    return tokenHasExpired;
  } catch (e) {
    console.error(e);
    return true;
  }
};

const login = async (credentials: {
  username: string;
  password: string;
  deviceId: string;
}) => {
  const loginResponse = await loginFetch(
    `${config.baseUrl}/v2/login`,
    credentials
  );

  if (!loginResponse.ok) {
    return false;
  }

  const json = await loginResponse.json();

  if (!(json as LoginResponse).refreshToken) {
    return false;
  }

  const jwtResponse: LoginResponse = json as LoginResponse;

  return jwtResponse;
};

const loginFetch = async (
  url: string,
  credentials: { username: string; password: string }
) => {
  const postData = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  };
  return fetch(url, postData);
};

type LoginResponse = {
  accessToken: string;
  refreshToken: string;
  pin?: string;
};

export default useAuthV3;
