import { useEffect } from 'react';
import useAuthV3 from './hooks/useAuthV3';
import { LoadingWand } from '@ordr-as/umami-react';
import useRedirectUrl from '../../hooks/useRedirectUrl';
import useIOS from './hooks/useIOS';

const RedirectLoggedInUser = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const userInputRedirectUrl = urlParams.get('redirect_url');
  const clientId = urlParams.get('client_id');
  const nonce = urlParams.get('nonce');
  const locationIdString =
    urlParams.get('location_id') || localStorage.getItem('location_id');
  const locationId = locationIdString ? parseInt(locationIdString) : undefined;
  const { iosAppVersion, iosReady } = useIOS();

  const { redirectUrl, redirectUrlError } = useRedirectUrl(
    userInputRedirectUrl,
    clientId
  );

  const onLoginSuccess = (token: string) => {
    if (redirectUrlError) {
      onLoginFailed();
    }
    const urlParams = new URLSearchParams();
    urlParams.set('refresh-token', token);
    urlParams.set('nonce', nonce || '');
    window.location.replace(`${redirectUrl}?${urlParams.toString()}`);
  };

  const onLoginFailed = () => {
    if (locationId) {
      urlParams.set('location_id', locationId.toString());
      window.location.replace(`/v3/pin?${urlParams.toString()}`);
      return;
    }

    window.location.replace(`/v3/login?${urlParams.toString()}`);
  };

  const { checkTokenInLocalStorage } = useAuthV3({
    onSuccess: onLoginSuccess,
    onFailure: onLoginFailed,
  });

  useEffect(() => {
    if (iosAppVersion && !iosReady) {
      return;
    }

    if (redirectUrl && !redirectUrlError) {
      checkTokenInLocalStorage();
    }
  }, [
    checkTokenInLocalStorage,
    redirectUrl,
    redirectUrlError,
    iosAppVersion,
    iosReady,
  ]);

  return (
    <div className="flex min-h-screen justify-center items-center">
      <LoadingWand />
    </div>
  );
};

export default RedirectLoggedInUser;
