import { useMutation } from '@tanstack/react-query';
import config from '../../../config';
import useDeviceId from './useDeviceId';
import { setAuthenticationUpdatedCookie } from '../../../cookie';

type LogoutRequest = { token: string; deviceId: string };

const useLogout = () => {
  const deviceId = useDeviceId();

  return useMutation({
    mutationKey: ['logout'],
    mutationFn: (dto: { token: string }) => logout({ ...dto, deviceId }),
    onSuccess: () => {
      localStorage.removeItem('REFRESH_TOKEN');
      setAuthenticationUpdatedCookie();
    },
  });
};

const logout = async (logoutRequest: LogoutRequest) => {
  const logoutResponse = await logoutFetch(
    `${config.baseUrl}/v1/logout`,
    logoutRequest
  );

  if (!logoutResponse.ok) {
    throw new Error('Failed to logout');
  }
};

const logoutFetch = async (url: string, logoutRequest: LogoutRequest) => {
  const postData = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(logoutRequest),
  };
  return fetch(url, postData);
};

export default useLogout;
